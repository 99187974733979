import React, { createContext, FC, ReactNode } from 'react';

import { Organization, OrganizationAccountType, useMeQuery, User } from '../types/api.graphql';
import { alphabetizeComparer } from '../utils/sort';
import { useAuthContext } from './AuthContext';

type Nullable<T> = T | null;

interface SessionContextProps {
  me: Nullable<User>;
  hasDealWithPaidSubscription: boolean;
  activeDealerships: ReadonlyArray<Organization>;
}

interface SessionProviderProps {
  children: ReactNode;
}

const SessionContext = createContext<SessionContextProps>({
  me: null,
  hasDealWithPaidSubscription: false,
  activeDealerships: [],
});

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const { authToken } = useAuthContext();
  const { data } = useMeQuery({ skip: !authToken });

  const hasDealWithPaidSubscription = data?.me?.organizations?.some((org) => org?.paidSubscription);
  const activeDealerships =
    data?.me?.organizations
      ?.filter(({ cif, accountType }) => !!cif && accountType === OrganizationAccountType.Compliance)
      ?.sort(alphabetizeComparer((item) => item.name, 'ascending', { ignorePunctuation: true })) || [];

  return (
    <SessionContext.Provider
      value={{
        me: data?.me || null,
        hasDealWithPaidSubscription,
        activeDealerships,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext: () => SessionContextProps = () => {
  return React.useContext(SessionContext);
};
